const ReactjsIcon = (props) => {
  return (
    <svg
      width={20}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      xmlnsserif="http://www.serif.com/"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <rect
        id="Icons"
        x="-1088"
        y="-64"
        width="1280"
        height="800"
        style={{ fill: "none" }}
      />

      <g id="Icons1" serifid="Icons">
        <g id="Strike"></g>

        <g id="H1"></g>

        <g id="H2"></g>

        <g id="H3"></g>

        <g id="list-ul"></g>

        <g id="hamburger-1"></g>

        <g id="hamburger-2"></g>

        <g id="list-ol"></g>

        <g id="list-task"></g>

        <g id="trash"></g>

        <g id="vertical-menu"></g>

        <g id="horizontal-menu"></g>

        <g id="sidebar-2"></g>

        <g id="Pen"></g>

        <g id="Pen1" serifid="Pen"></g>

        <g id="clock"></g>

        <g id="external-link"></g>

        <g id="hr"></g>

        <g id="info"></g>

        <g id="warning"></g>

        <g id="plus-circle"></g>

        <g id="minus-circle"></g>

        <g id="vue"></g>

        <g id="cog"></g>

        <g id="logo"></g>

        <g id="radio-check"></g>

        <g id="eye-slash"></g>

        <g id="eye"></g>

        <g id="toggle-off"></g>

        <g id="shredder"></g>

        <g id="spinner--loading--dots-" serifid="spinner [loading, dots]"></g>

        <g id="react">
          <circle
            cx="32.001"
            cy="31.955"
            r="4.478"
            style={{ fill: "#00d8ff" }}
          />

          <path
            d="M32.33,22.516c7.635,0.052 15.965,0.609 21.683,5.708c0.168,0.15 0.33,0.306 0.488,0.467c1.349,1.375 2.054,3.595 0.965,5.422c-2.234,3.751 -7.23,5.387 -12.067,6.394c-7.234,1.506 -14.798,1.518 -22.029,0.192c-4.161,-0.764 -8.416,-2.103 -11.373,-4.904c-1.151,-1.09 -2.135,-2.524 -1.981,-4.12c0.25,-2.582 2.727,-4.239 4.812,-5.361c5.791,-3.116 12.847,-3.813 19.502,-3.798Zm-0.554,1.173c-7.224,0.049 -15.043,0.51 -20.621,5.129c-0.195,0.161 -0.383,0.33 -0.564,0.507c-0.117,0.114 -0.23,0.233 -0.339,0.355c-0.979,1.1 -1.316,2.867 -0.392,4.188c0.93,1.329 2.342,2.288 3.796,3.07c5.438,2.924 11.864,3.443 18.129,3.465c6.343,0.023 12.884,-0.555 18.487,-3.452c2.232,-1.155 4.744,-2.851 4.655,-5.035c-0.082,-2.004 -2.036,-3.242 -3.499,-4.126c-0.396,-0.239 -0.803,-0.46 -1.216,-0.668c-5.562,-2.787 -12.08,-3.447 -18.436,-3.433Z"
            style={{ fill: "#00d8ff" }}
          />

          <path
            d="M42.115,10.703c2.793,0.071 4.24,3.429 4.431,5.909c0.038,0.493 0.052,0.988 0.046,1.483c-0.006,0.536 -0.035,1.072 -0.082,1.606c-0.589,6.612 -3.608,12.909 -7.163,18.724c-3.477,5.688 -7.717,11.36 -13.485,13.996c-1.907,0.872 -4.175,1.41 -5.863,0.437c-2.314,-1.333 -2.567,-4.451 -2.524,-6.816c0.011,-0.581 0.049,-1.162 0.109,-1.741c0.889,-8.56 5.228,-16.669 10.658,-23.655c3.168,-4.076 6.937,-8.119 11.632,-9.583c0.739,-0.231 1.326,-0.371 2.241,-0.36Zm-0.134,1.172c-3.279,0.052 -6.223,2.482 -8.83,5.007c-6.854,6.637 -11.905,15.464 -13.937,24.721c-0.157,0.717 -0.289,1.439 -0.386,2.166c-0.075,0.563 -0.13,1.129 -0.159,1.697c-0.023,0.452 -0.031,0.905 -0.017,1.358c0.01,0.354 0.033,0.708 0.072,1.06c0.029,0.269 0.068,0.537 0.117,0.803c0.037,0.197 0.08,0.393 0.13,0.588c0.041,0.158 0.087,0.315 0.139,0.471c0.409,1.233 1.463,2.411 2.878,2.45c3.301,0.09 6.409,-2.317 9.096,-4.933c4.717,-4.591 8.232,-10.36 10.978,-16.424c2.216,-4.896 4.243,-10.218 3.111,-15.607c-0.043,-0.204 -0.093,-0.406 -0.15,-0.606c-0.047,-0.163 -0.1,-0.324 -0.158,-0.483c-0.44,-1.199 -1.475,-2.271 -2.884,-2.268Z"
            style={{ fill: "#00d8ff" }}
          />

          <path
            d="M22.109,10.747c3.564,0.069 6.765,2.488 9.607,5.197c5.186,4.943 9.011,11.231 11.913,17.849c2.248,5.127 4.316,10.882 2.478,16.292c-0.579,1.705 -2.044,3.265 -3.997,3.305c-3.581,0.072 -6.9,-2.532 -9.78,-5.335c-7.225,-7.034 -12.589,-16.32 -14.427,-26.168c-0.132,-0.704 -0.237,-1.414 -0.309,-2.127c-0.059,-0.582 -0.096,-1.167 -0.106,-1.752c-0.008,-0.472 0.002,-0.944 0.035,-1.414c0.022,-0.314 0.054,-0.626 0.097,-0.937c0.041,-0.292 0.093,-0.583 0.158,-0.871c0.043,-0.191 0.091,-0.38 0.146,-0.568c0.539,-1.843 1.941,-3.485 4.185,-3.471Zm-0.135,1.173c-2.087,0.046 -3.042,2.507 -3.234,4.234c-0.039,0.354 -0.063,0.711 -0.074,1.068c-0.014,0.456 -0.008,0.913 0.015,1.369c0.328,6.599 3.278,12.979 6.838,18.821c3.352,5.5 7.4,10.978 12.968,13.794c1.608,0.813 3.562,1.452 4.951,0.684c1.742,-0.964 1.956,-3.261 2.049,-4.973c0.025,-0.466 0.028,-0.934 0.013,-1.401c-0.018,-0.586 -0.064,-1.171 -0.133,-1.753c-0.642,-5.437 -3.05,-10.582 -5.816,-15.444c-3.442,-6.048 -7.659,-12.076 -13.627,-15.225c-1.236,-0.652 -2.574,-1.185 -3.95,-1.174Z"
            style={{ fill: "#00d8ff" }}
          />
        </g>

        <g id="check-selected"></g>

        <g id="turn-off"></g>

        <g id="code-block"></g>

        <g id="user"></g>

        <g id="coffee-bean"></g>

        <g id="coffee-beans">
          <g id="coffee-bean1" serifid="coffee-bean"></g>
        </g>

        <g id="coffee-bean-filled"></g>

        <g id="coffee-beans-filled">
          <g id="coffee-bean2" serifid="coffee-bean"></g>
        </g>

        <g id="clipboard"></g>

        <g id="clipboard-paste"></g>

        <g id="clipboard-copy"></g>

        <g id="Layer1"></g>
      </g>
    </svg>
  );
};

export default ReactjsIcon;
